import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { format, isValid, parse } from 'date-fns';
import { addDays } from 'date-fns'; // You need to install 'date-fns' package to use addDays function

export interface Bloqueio {
  id: number;
  data_hora_inicial: string;
  data_hora_final: string;
}

export interface Resposta {
  [data: string]: {
    horarios: Horario[];
  };
}

interface Schedule {
  [date: string]: {
    [hour: number]: string[];
  };
}

export interface HorariosPorDia {
  [data: string]: Horario[];
}

export interface Especialidade {
  dias: string[];
  horario_inicial: string;
  horario_final: string;
  duracao_TempoAtendimento: string[];
  visivel: boolean;
}

interface Intervalo {
  inicial: string;
  final: string;
}

interface janelaDisponibilidade {
  id: number;
  dias_disponiveis: string[];
  valor: number;
  especialidades: string[];
  visivel: boolean;
  duracao_atendimento: string;
  horario_inicial: string;
  horario_final: string;
  duracao_TempoAtendimento: string[];
  tipo_atendimento: string[];
  perfil_negocio: any;
}

// interface Appointment {
//   inicial: string;
//   final: string;
// }

// interface Availability {
//   horario_inicial: number;
//   horario_final: number;
//   duracao_TempoAtendimento: string[];
//   visivel: boolean;
// }

// interface AvailableTimeSlot {
//   data: string;
//   horario_inicial: string;
// }

type Horario = {
  inicial: string;
  final: string;
};

interface Availabilities {
  [date: string]: {
    [id: string]: string[];
  };
}

type DiaSemana = 'Seg' | 'Ter' | 'Qua' | 'Qui' | 'Sex' | 'Sab' | 'Dom';

type Disponibilidade = Record<
  DiaSemana,
  {
    horario_inicial: number;
    horario_final: number;
    duracao_TempoAtendimento: string[];
    visivel: boolean;
  }
>;

type AgendamentosProfissionalByData = Record<string, { horarios: Horario[] }>;

type BloqueiosProfissionalByData = Record<string, { horarios: Horario[] }>;

///data mais proxima

type HorarioAgendamento = {
  horarios: Intervalo[];
  intervaloTotal?: string[]; // propriedade opcional
};

type HorarioBloqueio = {
  horarios: Intervalo[];
  intervaloTotal?: string[]; // propriedade opcional
};

type HorarioDiaSemana = {
  horario_inicial: number;
  horario_final: number;
  duracao_TempoAtendimento: string[];
  visivel: boolean;
};

type Profissional = {
  nome: string;
  hora_inicial_HoraFinal_PorDia: {
    [dia: string]: HorarioDiaSemana;
  };
  AgendamentosProfissionalByData: {
    [data: string]: HorarioAgendamento;
  };
  BloqueiosProfissionalByData: {
    [data: string]: HorarioBloqueio;
  };
};

type Profissionais = {
  [id: string]: Profissional;
};

@Injectable({
  providedIn: 'root',
})
export class AgendaService {
  ArrayAtendimentoTimes_Subject$ = new Subject<any>();

  strapiApiUrl = environment.strapiApiUrl;
  refeshProfissionais$ = new BehaviorSubject<boolean>(true);

  constructor(private http: HttpClient) { }

  createAgendamento(AgendamentoData: Object) {
    return this.http.post<any>(
      this.strapiApiUrl + '/agendamentos',
      AgendamentoData
    );
  }

  updateAgendamento(AgendamentoData: Object, AgendamentoId: string) {
    return this.http.put<any>(
      this.strapiApiUrl + '/agendamentos/' + AgendamentoId,
      AgendamentoData
    );
  }

  getAgendamentos_ultimos30dias(negocio_id) {
    const today = new Date();
    const lastMonthDate = new Date(today.setMonth(today.getMonth() - 1)).toISOString();

    return this.http.get<any>(
      this.strapiApiUrl +
      `/agendamentos?filters[$and][0][perfil_negocio][id][$eq]=${negocio_id}` +
      `&filters[$and][1][data][$gte]=${lastMonthDate}` +
      `&pagination[start]=0&pagination[limit]=1000` +
      `&populate[pet]=pet` +
      `&populate[tutors][populate][0]=dados_pessoais` +
      `&populate[profissional][populate][0]=dados_pessoais`
    );
  }


  getProfissionaisToFilterBySpeciality(perfil_negocio:any, especiality:string){
    return this.http.get<any>(this.strapiApiUrl + `/profissionals?filters[$or][0][perfil_negocio][id]=${perfil_negocio}&filters[$or][1][perfis_negocios_assoc][id]=${perfil_negocio}&filters[$and][2][especialidades_procedimentos][nome]=${especiality}&populate[0]=dados_pessoais&populate[1]=programacao&populate[2]=programacao.janela_disponibilidade&populate[3]=programacao.bloquear_agenda`)
  }

  getSpecialitiesByProfile(perfil_negocio:any){
    return this.http.get<any>(this.strapiApiUrl + `/profissionals?filters[$or][0][perfil_negocio][id]=${perfil_negocio}&filters[$or][1][perfis_negocios_assoc][id]=${perfil_negocio}&populate[0]=programacao&populate[1]=programacao.janela_disponibilidade`)
  }


  getAgendamentos_byultimosNdias(negocio_id, ndias) {
    const today = new Date();
    const lastMonthDate = new Date(today.setDate(today.getDate() - ndias)).toISOString();

    return this.http.get<any>(
      this.strapiApiUrl +
      `/agendamentos?filters[$and][0][perfil_negocio][id][$eq]=${negocio_id}` +
      `&filters[$and][1][data][$gte]=${lastMonthDate}` +
      `&pagination[start]=0&pagination[limit]=1000` +
      `&populate[pet]=pet` +
      `&populate[tutors][populate][0]=dados_pessoais` +
      `&populate[profissional][populate][0]=dados_pessoais` +
      `&populate[avaliation]=avaliation`
    );
  }

  checkPresentAgendamentosCount(negocio_id:any){

    let now = new Date(); 
    let end = new Date().toISOString();

    return this.http.get<any>(this.strapiApiUrl + `/agendamentos?filters[$and][0][perfil_negocio][id][$eq]=${negocio_id}` +
      `&filters[$and][1][data][$gte]=${addDays(now, -1).toISOString()}&filters[$and][2][data][$lte]=${addDays(now, 60).toISOString()}` +
      `&pagination[start]=0&pagination[limit]=1000`)
  }

  getAgendamentos_to_avaliations(negocio_id, page = 0) {
    return this.http.get<any>(
      this.strapiApiUrl +
      `/agendamentos?filters[$and][0][perfil_negocio][id][$eq]=${negocio_id}` +
      `&pagination[start]=${page}&pagination[limit]=40` +
      `&populate[pet]=pet` +
      `&populate[tutors][populate][0]=dados_pessoais` +
      `&populate[profissional][populate][0]=dados_pessoais` +
      // `&populate[avaliation]=avaliation` +
      `&populate[avaliation][populate][0]=notes`
    );
  }

  filterAvaliations(negocio_id, page = 0, filterStatusValue:string, filterCreatedAtValue:any, filterProfessionalValue:any){

    var filterStatus = '';
    var filterProfissional = '';

    if(filterStatusValue){
      filterStatus = `filters[$and][0][status]=${filterStatusValue}&`;
    }
    if(filterProfessionalValue || filterProfessionalValue != null){
      filterProfissional = `&filters[$and][2][agendamento][profissional][id]=${filterProfessionalValue}`;
    }

    return this.http.get<any>(this.strapiApiUrl + `/avaliations?pagination[start]=${page}&pagination[limit]=40&${filterStatus}filters[$and][1][createdAt][$gte]=${filterCreatedAtValue}${filterProfissional}&filters[$and][3][agendamento][perfil_negocio][id]=${negocio_id}&populate[0]=notes&populate[1]=agendamento&populate[agendamento][populate][2]=profissional.dados_pessoais&populate[notes][populate][5]=*&populate[agendamento][populate][3]=pet&populate[agendamento][populate][4]=tutors.dados_pessoais&populate[agendamento][populate][5]=tutors.avatar`)

  }

  putAvaliation(AvaliationId: string, AvaliationData: Object,) {
    return this.http.put<any>(
      this.strapiApiUrl + '/avaliations/' + AvaliationId,
      AvaliationData
    );
  }

  postNotes(ObjectData: Object,) {
    return this.http.post<any>(
      this.strapiApiUrl + '/notes',
      ObjectData
    );
  }

  putNotes(NotesId: string, NotesData: Object,) {
    return this.http.put<any>(
      this.strapiApiUrl + '/notes/' + NotesId,
      NotesData
    );
  }

  deleteNotes(NotesId: string) {
    return this.http.delete<any>(
      this.strapiApiUrl + '/notes/' + NotesId,
    );
  }







  getAgendamentosByNegocio(negocio_id) {
    return this.http.get<any>(
      this.strapiApiUrl +
      '/agendamentos?filters[$and][0][perfil_negocio][id][$eq]=' +
      negocio_id +
      '&populate[pet]=pet&populate[tutors][populate][0]=dados_pessoais&populate[profissional][populate][0]=dados_pessoais&sort[0]=data%3Adesc&sort[1]=horario_inicial%3Aasc'
    );
  }




  getPetFaltas(negocio_id, PetId, status) {
    return this.http.get<any>(
      this.strapiApiUrl +
      '/agendamentos?filters[$and][0][perfil_negocio][id][$eq]=' +
      negocio_id +
      '&filters[$and][1][pet][id][$eq]=' +
      PetId +
      '&filters[$and][2][status][$eq]=' +
      status +
      '&populate[pet]=pet'
    );
  }

  getPetUltimaconsulta(
    negocio_id: string,
    PetId: string,
    tipo_agendamento: string,
    status: string
  ) {
    const baseUrl = this.strapiApiUrl;
    const endpoint = '/agendamentos';
    const filters = `filters[$and][0][perfil_negocio][id][$eq]=${negocio_id}&filters[$and][1][pet][id][$eq]=${PetId}&filters[$and][2][tipo_agendamento][$eq]=${tipo_agendamento}&filters[$and][2][status][$eq]=${status}`;
    const populate = 'populate[pet]=pet';
    const sort = 'sort=data%3Adesc';
    const pagination = 'pagination[limit]=1';

    const url = `${baseUrl}${endpoint}?${filters}&${populate}&${sort}&${pagination}`;

    return this.http.get<any>(url);
  }

  getAgendamentosDoMesByNegocio(negocio_id) {
    const now = new Date(); // Obtem a data atual
    const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1); // Obtem o primeiro dia do mês atual
    const lastDayOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0); // Obtem o último dia do mês atual
    const apiUrl = `${this.strapiApiUrl
      }/agendamentos?filters[$and][0][perfil_negocio][id][$eq]=${negocio_id}&pagination[start]=0&pagination[limit]=1000&filters[$and][1][data][$gte]=${addDays(firstDayOfMonth, -60).toISOString()}&filters[$and][2][data][$lte]=${addDays(lastDayOfMonth,60).toISOString()}&populate[pet][populate][0]=avatar&populate[tutors][populate][0]=dados_pessoais&populate[profissional][populate][0]=dados_pessoais&populate[profissional][populate][1]=especialidades_procedimentos&sort[0]=data%3Adesc&sort[1]=horario_inicial%3Aasc`;
    return this.http.get<any>(apiUrl);
  }

  getAgendamentosByProfissionalAndDate(date:any, negocio_id:number, professional_id:number){
    const apiUrl = `${this.strapiApiUrl
      }/agendamentos?filters[$and][0][perfil_negocio][id][$eq]=${negocio_id}&filters[$and][1][profissional][id][$eq]=${professional_id}&filters[$and][1][data][$eq]=${date}&pagination[start]=0&pagination[limit]=1000&sort[0]=data%3Adesc&sort[1]=horario_inicial%3Aasc`;
      return this.http.get<any>(apiUrl);
  }

  getAgendamentosDoMesByProfissionalNegocio(negocio_id, profissional_id) {
    const now = new Date(); // Obtem a data atual
    const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1); // Obtem o primeiro dia do mês atual
    const lastDayOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0); // Obtem o último dia do mês atual
    const apiUrl = `${this.strapiApiUrl
      }/agendamentos?filters[$and][0][perfil_negocio][id][$eq]=${negocio_id}&pagination[start]=0&pagination[limit]=1000&filters[$and][1][profissional][id][$eq]=${profissional_id}&filters[$and][2][data][$gte]=${addDays(firstDayOfMonth, -60).toISOString()}&filters[$and][3][data][$lte]=${addDays(lastDayOfMonth,60).toISOString()}&populate[pet]=pet&populate[tutors][populate][0]=dados_pessoais&populate[profissional][populate][0]=dados_pessoais&populate[profissional][populate][1]=programacao.janela_disponibilidade&populate[profissional][populate][2]=programacao.bloquear_agenda&sort[0]=data%3Adesc&sort[1]=horario_inicial%3Aasc`;
    return this.http.get<any>(apiUrl);
  }



  getProfissionalAgendamentos(AgendamentoId: number) {
    return this.http.get<any>(
      this.strapiApiUrl +
      '/agendamentos?filters[id][$eq]=' +
      AgendamentoId +
      '&populate=deep'
    );
  }

  getAgendamentoByID(AgendamentoId: number) {
    return this.http.get<any>(
      this.strapiApiUrl +
      '/agendamentos?filters[id][$eq]=' +
      AgendamentoId +
      '&populate[pet][populate][0]=avatar&populate[tutors][populate][0]=dados_pessoais&populate[profissional][populate][0]=dados_pessoais&populate[profissional][populate][1]=especialidades_procedimentos'
    );
  }

  removeAgendamento(AgendamentoId: number) {
    return this.http.delete(
      this.strapiApiUrl + '/agendamentos/' + AgendamentoId
    );
  }

  getEspecialidadesArrayFromProfissional(
    janelaDisponibilidade: janelaDisponibilidade[],
    perfilNegocio

  ): string[] {

    console.log('janelaDisponibilidade', janelaDisponibilidade, perfilNegocio)
    const allSpecialties = janelaDisponibilidade
      ?.filter((janela) => { return janela?.visivel && (+janela?.perfil_negocio === +perfilNegocio) })
      .reduce((acc, janelaDisponibilidade) => {
        return acc?.concat(janelaDisponibilidade?.especialidades);
      }, []);

    // A função reduce percorre o array de objetos do tipo janelaDisponibilidade e, a cada iteração,
    //concatena o array de especialidades do objeto atual no acumulador.No final,
    //ela utiliza a classe Set para remover elementos duplicados e retorna um array com as especialidades únicas encontradas.
    return Array?.from(new Set(allSpecialties));
  }

  /**
   * Transforma um array de objetos de profissionais em um objeto organizado por data e horário, contendo as informações dos agendamentos de cada profissional.
   * @param {Array} profissionais - Array de objetos contendo as informações dos profissionais, incluindo um array de agendamentos.
   * @returns {Object} Objeto organizado por data e horário, contendo as informações dos agendamentos de cada profissional.
   */
  transformAgendamentos(profissionais: any[]) {
    // Inicializa o objeto que será utilizado para armazenar os agendamentos por data e horário
    let agendamentosByData = {};

    // Itera sobre cada profissional
    profissionais.forEach((profissional) => {
      // Obtém o ID do profissional
      let id = profissional.id;

      // Cria um objeto vazio para armazenar os agendamentos desse profissional
      agendamentosByData[id] = {};

      // Itera sobre cada agendamento do profissional
      profissional?.attributes?.agendamentos?.data.forEach((agendamento) => {
        // Obtém a data do agendamento e cria um objeto Date a partir dela, ajustando o fuso horário para GMT-0300

        if (agendamento?.attributes?.status === 'cancelado') {
          return
        }
        const dataO = new Date(`${agendamento?.attributes?.data}T03:00:00`);
        dataO.setHours(21, 0, 0, 0);
        const data1 = (dataO.toISOString());
        let data = new Date(data1);

        // Formata a data em uma string com o formato YYYY-MM-DD
        if (data.getMonth() + 1 > 9) {
          var dataString = `${data.getFullYear()}-${data.getMonth() + 1
            }-${data.getDate()}`;
        } else {
          var dataString = `${data.getFullYear()}-0${data.getMonth() + 1}-${data
            .getDate()
            .toString()
            .padStart(2, '0')}`;
        }

        console.log('DATA STRING AGENDAMENTO - AGENDA SERVICE', data1)

        // Se ainda não existe um objeto para essa data no objeto de agendamentos do profissional, "criar" um objeto com a propriedade "horários"
        if (!agendamentosByData[id][dataString]) {
          agendamentosByData[id][dataString] = {
            horarios: [],
          };
        }

        // Adiciona um objeto com as informações do horário do agendamento na lista de horários da data correspondente
        agendamentosByData[id][dataString]?.['horarios']?.push({
          inicial: agendamento?.attributes?.horario_inicial,
          final: agendamento?.attributes?.horario_final,
          id: agendamento?.id,
        });
      });
    });

    // Retorna o objeto de agendamentos organizado por data e horário
    // console.log('agendamentosByData do profissional', agendamentosByData)
    return agendamentosByData;
  }

  /**
   * Transforma um array de profissionais em um objeto de bloqueios de agenda, em que cada profissional é identificado pelo seu id e possui um array de horários bloqueados.
   * @param {Array} profissionais - Array de objetos que contêm informações dos profissionais.
   * @returns {Object} Objeto de bloqueios de agenda, em que cada profissional é identificado pelo seu id e possui um array de horários bloqueados.
   */
  transformBloqueios(profissionais: any[]) {
    let bloqueios = {};

    profissionais.forEach((profissional) => {
      const id = profissional?.id;

      const horarios =
        profissional.attributes?.programacao?.bloquear_agenda?.map(
          (bloqueio) => {
            // console.log('profissional bloqueio', profissional.id, bloqueio)
            const bloqueiosByData = this.processarBloqueio(bloqueio);
            // this.getHorarios(bloqueio)
            const dataHoraI = new Date(bloqueio?.data_hora_inicial);
            const dataHoraF = new Date(bloqueio?.data_hora_final);
            const horarioStringI = dataHoraI?.toTimeString()?.substring(0, 8);
            const horarioStringF = dataHoraF?.toTimeString()?.substring(0, 8);

            // console.log('horarioStringF', horarioStringF, 'horarioStringI',horarioStringI);

            return {
              datainicial: dataHoraI,
              datafinal: dataHoraF,
              horanicial: horarioStringI,
              horafinal: horarioStringF,
              bloqueiosByData: bloqueiosByData,
            };
          }
        );

      bloqueios[id] = { horarios };
    });

    // console.log('bloqueios do profissional', bloqueios);

    return this.extractBloqueiosByData(bloqueios);
  }

  extractBloqueiosByData(transformedBloqueios) {
    let result = {};

    for (const professionalId in transformedBloqueios) {
      const horarios = transformedBloqueios?.[professionalId]?.horarios;
      let bloqueiosByData = {};

      horarios?.forEach((horario) => {
        Object?.assign(bloqueiosByData, horario?.bloqueiosByData);
      });

      result[professionalId] = bloqueiosByData;
    }

    return result;
  }

  /**
   * Converte um array de objetos contendo informações de horários de atendimento para um objeto que mapeia os horários iniciais e finais por dia da semana.
   * @param {Array} array - Um array de objetos contendo informações de horários de atendimento.
   * @returns {Object} Um objeto que mapeia os horários iniciais e finais por dia da semana, no formato { dia_da_semana: { horario_inicial, horario_final, duracao_TempoAtendimento, visivel } }.
   */
  Hora_inicial_HoraFinal_PorDia(array) {
    const obj = {};
    array?.forEach((item) => {
      item?.dias?.forEach((day) => {
        let days;
        if (day === 'Todos os dias') {
          days = ['Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb', 'Dom'];
        } else if (day === 'Seg - Sex') {
          days = ['Seg', 'Ter', 'Qua', 'Qui', 'Sex'];
        } else if (day === 'Sáb - Dom') {
          days = ['Sáb', 'Dom'];
        } else {
          days = [day];
        }
        days?.forEach((d) => {
          if (item?.visivel) {
            // retorna apenas janelas visiveis
            obj[d] = {
              horario_inicial: new Date(item?.horario_inicial).getHours(),
              horario_final: new Date(item?.horario_final).getHours(),
              duracao_TempoAtendimento: item?.duracao_TempoAtendimento,
              visivel: item?.visivel,
              valor: item?.valor,
            };
          }
        });
      });
    });
    console.log('TESTEEEEEEEEEEEEEEEEEEEEEEEEEEEEE', obj);
    return obj;
  }
  /**
   * Processa um objeto de bloqueio de horário e retorna uma resposta com os horários bloqueados por dia.
   * @param {Object} bloqueio - Objeto que contém as propriedades 'data_hora_inicial' e 'data_hora_final', representando os horários de início e fim do bloqueio no formato ISO 8601.
   * @returns {Object} Objeto que mapeia datas em objetos que contêm uma propriedade 'horarios', que é um array de objetos com propriedades 'inicial' e 'final', representando os horários bloqueados em cada dia no formato 'HH:mm:ss'.
   */
  processarBloqueio(bloqueio): Resposta {
    // console.log(bloqueio)

    const resposta: Resposta = {};

    const inicio = new Date(bloqueio?.data_hora_inicial);
    const fim = new Date(bloqueio?.data_hora_final);
    const fimString = new Date(bloqueio?.data_hora_final)
      .toLocaleString()
      ?.replace(/(\d{2})\/(\d{2})\/(\d{4}).*/, '$3-$2-$1');
    const inicioString = new Date(bloqueio?.data_hora_inicial)
      .toLocaleString()
      ?.replace(/(\d{2})\/(\d{2})\/(\d{4}).*/, '$3-$2-$1');

    console.log('PROCESSAMENTOS DE BLOQUEIOS',inicio, inicioString, fim, fimString)

    const umDia = 24 * 60 * 60 * 1000; // 1 dia em milissegundos

    for (
      let data = inicio;
      data?.getDate() <= fim?.getDate() && data?.getMonth() === fim?.getMonth();
      data?.setTime(data?.getTime() + umDia)
    ) {
      // console.log(data)

      const chave = data
        ?.toLocaleString()
        ?.replace(/(\d{2})\/(\d{2})\/(\d{4}).*/, '$3-$2-$1');
      var hora = data?.toTimeString()?.substring(0, 8);

      console.log('CHAVE DO OBJ BLOQUEIO',chave)

      if (!resposta[chave]) {
        resposta[chave] = {
          horarios: [],
        };
      }

      if (inicioString === fimString) {
        const chave = inicio
          .toLocaleString()
          ?.replace(/(\d{2})\/(\d{2})\/(\d{4}).*/, '$3-$2-$1');
        var horaI = inicio.toTimeString()?.substring(0, 8);
        var horaF = fim.toTimeString()?.substring(0, 8);

        resposta[chave].horarios.push({
          inicial: horaI,
          final: horaF,
        });
      } else if (
        data.getTime() === inicio.getTime() &&
        !(
          data
            .toLocaleString()
            ?.replace(/(\d{2})\/(\d{2})\/(\d{4}).*/, '$3-$2-$1') == fimString
        )
      ) {
        resposta[chave].horarios.push({
          inicial: hora,
          final: '23:59:59',
        });
      } else if (
        data
          .toLocaleString()
          ?.replace(/(\d{2})\/(\d{2})\/(\d{4}).*/, '$3-$2-$1') == fimString
      ) {
        resposta[chave].horarios.push({
          inicial: '00:00:00',
          final: fim.toTimeString()?.substring(0, 8),
        });
      } else {
        resposta[chave].horarios.push({
          inicial: '00:00:00',
          final: '23:59:59',
        });
      }
    }

    // console.log(resposta)

    return resposta;
  }

  processarTempoMinimoAviso(bloqueio, desabledAntecedencias:Boolean = false): Resposta {
    console.log(bloqueio)
    console.log('desabledAntecedencias',desabledAntecedencias)
    const resposta: Resposta = {};
    const inicio = new Date(bloqueio?.data_hora_inicial);
    const fim = new Date(bloqueio?.data_hora_final);
    // datas de início e fim para strings no formato AAAA-MM-DD
    const fimString = new Date(bloqueio?.data_hora_final)
      .toLocaleString()
      ?.replace(/(\d{2})\/(\d{2})\/(\d{4}).*/, '$3-$2-$1');
    const inicioString = new Date(bloqueio?.data_hora_inicial)
      .toLocaleString()
      ?.replace(/(\d{2})\/(\d{2})\/(\d{4}).*/, '$3-$2-$1');
    //console.log(inicio, inicioString, fim, fimString)
    var umDia = 24 * 60 * 60 * 1000; // 1 dia em milissegundos
    // Itera pelas datas entre o início e fim do bloqueio

    if(desabledAntecedencias){
      umDia = 1 * 60 * 60 * 1000
    }

    for (
      let data = inicio;
      data?.getDate() <= fim?.getDate() && data?.getMonth() === fim?.getMonth();
      data?.setTime(data?.getTime() + umDia)
    ) {
      console.log('data + umDia', data)
      // Formata a data atual da iteração no formato AAAA-MM-DD
      const chave = data
        ?.toLocaleString()
        ?.replace(/(\d{2})\/(\d{2})\/(\d{4}).*/, '$3-$2-$1');
      var hora = data?.toTimeString()?.substring(0, 8); // Extrai a hora atual da data da iteração

      if (!resposta[chave]) {
        resposta[chave] = {
          horarios: [],
        };
      }
      // Caso a data de início seja igual à data de fim
      if (inicioString === fimString) {
        const chave = inicio
          .toLocaleString()
          ?.replace(/(\d{2})\/(\d{2})\/(\d{4}).*/, '$3-$2-$1');
        var horaI = inicio.toTimeString()?.substring(0, 8);
        var horaF = fim.toTimeString()?.substring(0, 8);

        resposta[chave].horarios.push({
          inicial: this.arredondarParaProximoQuartoHora(horaI),
          final: this.arredondarParaProximoQuartoHora(horaF),
        });
      } else if (
        // Caso a data da iteração seja igual à data de início e diferente da data de fim
        data.toLocaleString()
          ?.replace(/(\d{2})\/(\d{2})\/(\d{4}).*/, '$3-$2-$1') === inicioString &&
        !(
          data
            .toLocaleString()
            ?.replace(/(\d{2})\/(\d{2})\/(\d{4}).*/, '$3-$2-$1') == fimString
        )
      ) {
        resposta[chave].horarios.push({
          inicial: this.arredondarParaProximoQuartoHora(hora),
          final: '23:59:59',
        });
      } else if (
        data
          .toLocaleString()
          ?.replace(/(\d{2})\/(\d{2})\/(\d{4}).*/, '$3-$2-$1') == fimString
      ) {
        resposta[chave].horarios.push({
          inicial: '00:00:00',
          final: this.arredondarParaProximoQuartoHora(
            fim.toTimeString()?.substring(0, 8)
          ),
        });
      } else {
        resposta[chave].horarios.push({
          inicial: '00:00:00',
          final: '23:59:59',
        });
      }


    }

    console.group('processarTempoMinimoAviso')
    console.log('resposta', resposta)
    console.log('bloqueio', bloqueio)
    console.log('inicio', inicio)
    console.log('fim', fim)
    console.log('fimString', fimString)
    console.log('inicioString', inicioString)
    console.log('umDia', umDia)
    console.log('hora', hora)
    console.groupEnd()



    // console.log('processarTempoMinimoAviso', resposta)

    return this.adicionarIntervaloTotal({ ...resposta });
  }

  processarTempoMinimoAviso2(bloqueio): Resposta {
    console.log(bloqueio)
    //

    const resposta: Resposta = {};

    const inicio = new Date(bloqueio?.data_hora_inicial);
    const fim = new Date(bloqueio?.data_hora_final);

    // datas de início e fim para strings no formato AAAA-MM-DD
    const fimString = new Date(bloqueio?.data_hora_final)
      .toLocaleString()
      ?.replace(/(\d{2})\/(\d{2})\/(\d{4}).*/, '$3-$2-$1');
    const inicioString = new Date(bloqueio?.data_hora_inicial)
      .toLocaleString()
      ?.replace(/(\d{2})\/(\d{2})\/(\d{4}).*/, '$3-$2-$1');

    //console.log(inicio, inicioString, fim, fimString)

    const umDia = 24 * 60 * 60 * 1000; // 1 dia em milissegundos

    // Itera pelas datas entre o início e fim do bloqueio
    for (
      let data = inicio;
      data?.getDate() <= fim?.getDate() && data?.getMonth() === fim?.getMonth();
      data?.setTime(data?.getTime() + umDia)
    ) {
      console.log('data + umDia', data)
      // Formata a data atual da iteração no formato AAAA-MM-DD

      const chave = data
        ?.toLocaleString()
        ?.replace(/(\d{2})\/(\d{2})\/(\d{4}).*/, '$3-$2-$1');
      var hora = data?.toTimeString()?.substring(0, 8); // Extrai a hora atual da data da iteração

      if (!resposta[chave]) {
        resposta[chave] = {
          horarios: [],
        };
      }

      // Caso a data de início seja igual à data de fim
      if (inicioString === fimString) {
        const chave = inicio
          .toLocaleString()
          ?.replace(/(\d{2})\/(\d{2})\/(\d{4}).*/, '$3-$2-$1');
        var horaI = inicio.toTimeString()?.substring(0, 8);
        var horaF = fim.toTimeString()?.substring(0, 8);

        resposta[chave].horarios.push({
          inicial: this.arredondarParaProximoQuartoHora(horaI),
          final: this.arredondarParaProximoQuartoHora(horaF),
        });
      } else if (
        // Caso a data da iteração seja igual à data de início e diferente da data de fim
        data.toLocaleString()
          ?.replace(/(\d{2})\/(\d{2})\/(\d{4}).*/, '$3-$2-$1') === inicioString &&
        !(
          data
            .toLocaleString()
            ?.replace(/(\d{2})\/(\d{2})\/(\d{4}).*/, '$3-$2-$1') == fimString
        )
      ) {
        resposta[chave].horarios.push({
          inicial: this.arredondarParaProximoQuartoHora(hora),
          final: '23:59:59',
        });
      } else if (
        data
          .toLocaleString()
          ?.replace(/(\d{2})\/(\d{2})\/(\d{4}).*/, '$3-$2-$1') == fimString
      ) {
        resposta[chave].horarios.push({
          inicial: '00:00:00',
          final: this.arredondarParaProximoQuartoHora(
            fim.toTimeString()?.substring(0, 8)
          ),
        });
      } else {
        resposta[chave].horarios.push({
          inicial: '00:00:00',
          final: '23:59:59',
        });
      }


    }

    console.group('processarTempoMinimoAviso')
    console.log('resposta', resposta)
    console.log('bloqueio', bloqueio)
    console.log('inicio', inicio)
    console.log('fim', fim)
    console.log('fimString', fimString)
    console.log('inicioString', inicioString)
    console.log('umDia', umDia)
    console.log('hora', hora)
    console.groupEnd()



    // console.log('processarTempoMinimoAviso', resposta)

    return this.adicionarIntervaloTotal({ ...resposta });
  }

  adicionarIntervaloTotal(processarTempoMinimoAviso) {
    const updatedProcessarTempoMinimoAviso = {};

    for (const key in processarTempoMinimoAviso) {
      if (processarTempoMinimoAviso.hasOwnProperty(key)) {
        const dataHorarios = processarTempoMinimoAviso[key];
        const intervaloTotal = this.gerarHorarios(dataHorarios.horarios);

        updatedProcessarTempoMinimoAviso[key] = {
          ...dataHorarios,
          intervaloTotal,
        };
      }
    }
    // console.log('updatedProcessarTempoMinimoAviso', updatedProcessarTempoMinimoAviso)
    return updatedProcessarTempoMinimoAviso;
  }

  excluirHorariosAntesTempoMinimoAviso(
    datas_Atendimento,
    updatedProcessarTempoMinimoAviso
  ) {
    let datasAtendimento1 = { ...datas_Atendimento };
    let updatedProcessarTempoMinimoAviso1 = {
      ...updatedProcessarTempoMinimoAviso,
    };

    for (const dataString in updatedProcessarTempoMinimoAviso) {
      for (const id in datasAtendimento1[dataString]) {
      }
    }
  }

  arredondarParaProximoQuartoHora(hora: string): string {
    const partes = hora.split(':');
    const horas = parseInt(partes[0]);
    const minutos = parseInt(partes[1]);
    const segundos = parseInt(partes[2]);

    const totalMinutos = horas * 60 + minutos + Math.round(segundos / 60);
    const minutosArredondados = Math.round(totalMinutos / 15) * 15;

    const horasArredondadas = Math.floor(minutosArredondados / 60) % 24;
    const minutosRestantes = minutosArredondados % 60;

    console.log('>>>>>>>>>>>>hora', hora, `${horasArredondadas.toString().padStart(2, '0')}:${minutosRestantes
      .toString()
      .padStart(2, '0')}:00`);

    return `${horasArredondadas.toString().padStart(2, '0')}:${minutosRestantes
      .toString()
      .padStart(2, '0')}:00`;
  }

  // getHorarios(bloqueio: Bloqueio): HorariosPorDia {
  //   const horariosPorDia: HorariosPorDia = {};

  //   const [anoInicial, mesInicial, diaInicial] = bloqueio.data_hora_inicial
  //     .split("T")[0]
  //     .split("-");
  //   const [anoFinal, mesFinal, diaFinal] = bloqueio.data_hora_final
  //     .split("T")[0]
  //     .split("-");

  //   const dataInicial = new Date(+anoInicial, +mesInicial - 1, +diaInicial);
  //   const dataFinal = new Date(+anoFinal, +mesFinal - 1, +diaFinal);

  //   for (let data = dataInicial; data <= dataFinal; data.setDate(data.getDate() + 1)) {
  //     const dataString = data.toISOString().split("T")[0];
  //     const horario: Horario = {
  //       inicial: bloqueio.data_hora_inicial.split("T")[1].slice(0, -5),
  //       final: bloqueio.data_hora_final.split("T")[1].slice(0, -5),
  //     };
  //     if (horariosPorDia[dataString]) {
  //       horariosPorDia[dataString].push(horario);
  //     } else {
  //       horariosPorDia[dataString] = [horario];
  //     }
  //   }
  //   console.log(horariosPorDia)

  //   return horariosPorDia;
  // }

  /**
   * Verifica se um novo agendamento pode ser criado com base nos horários de início e fim fornecidos e nas listas de horários bloqueados e horários já agendados.
   * @param {string} input_horario_inicial - Horário de início do novo agendamento, no formato 'HH:mm:ss'.
   * @param {string} input_horario_final - Horário de fim do novo agendamento, no formato 'HH:mm:ss'.
   * @param {string[]} horasBloqueioAgendaToblock - Array de horários bloqueados, no formato 'HH:mm:ss'.
   * @param {string[]} horasAgendadasToblock - Array de horários já agendados, no formato 'HH:mm:ss'.
   * @returns {boolean} Retorna true se o novo agendamento não puder ser criado devido à sobreposição de horários com os horários bloqueados ou horários já agendados.
   */
  RejectvalidateNewAgendamento(
    input_horario_inicial: string,
    input_horario_final: string,
    horasBloqueioAgendaToblock: string[],
    horasAgendadasToblock: string[]
  ): boolean {
    const intervaloNewAgendamento: string[] = [];
    const step = 15; // 15 minutos

    // Converte o horário inicial e final para objetos Date
    const inicio = new Date(`2022-01-01T${input_horario_inicial}`);
    const fim = new Date(`2022-01-01T${input_horario_final}`);

    // Percorre os intervalos de tempo com um passo de 15 minutos e adiciona ao array intervaloNewAgendamento
    let horaAtual = inicio;
    while (horaAtual <= fim) {
      intervaloNewAgendamento.push(
        horaAtual.toLocaleTimeString('pt-BR', { hour12: false })
      );
      horaAtual.setMinutes(horaAtual.getMinutes() + step);
    }

    // Cria um array de booleanos que indica se cada horário do intervaloNewAgendamento está presente em um dos dois arrays fornecidos
    const horariosBloqueados = intervaloNewAgendamento.map((horario) => {
      return (
        horasBloqueioAgendaToblock.includes(horario) ||
        horasAgendadasToblock.includes(horario)
      );
    });
    console.group('RejectvalidateNewAgendamento');
    console.log('inicio', inicio);
    console.log('fim', fim);

    console.log('intervaloNewAgendamento', intervaloNewAgendamento);
    console.log('horariosBloqueados', horariosBloqueados);
    console.groupEnd();
    // Verifica se o array de booleanos contém algum valor true e retorna o resultado
    return horariosBloqueados.includes(true);
  }

  /**
   * Retorna um array de horários disponíveis para agendamento, filtrando os horários que ocorrem antes de um tempo mínimo de aviso.
   * @param {Array} ArrayAtendimentoTimes - Array de strings que representam os horários de atendimento disponíveis, no formato 'HH:mm:ss'.
   * @param {string} tempo_minimo_aviso - O tempo mínimo de aviso exigido antes de agendar uma consulta, pode ser um dos valores: "tempo_15min", "tempo_30min", "tempo_45min", "tempo_1h", "tempo_1h15min", "tempo_1h30min", "tempo_1h45min", ou "tempo_2h".
   * @returns {Array} Array de horários disponíveis que ocorrem após o tempo mínimo de aviso, no formato 'HH:mm:ss'.
   * @throws {Error} Se o valor do parâmetro 'tempo_minimo_aviso' não for um dos valores válidos listados acima.
   */
  atualizarHorariosTempoAviso(
    ArrayAtendimentoTimes: string[],
    tempo_minimo_aviso: string
  ): string[] {
    // Obter a hora atual
    const agora = new Date();

    const horaAtual = new Date().getTime();

    // Converter tempo_minimo_aviso para milissegundos
    let tempoAviso = 0;
    switch (tempo_minimo_aviso) {
      case 'tempo_15min':
        tempoAviso = 15 * 60 * 1000;
        break;
      case 'tempo_30min':
        tempoAviso = 30 * 60 * 1000;
        break;
      case 'tempo_45min':
        tempoAviso = 45 * 60 * 1000;
        break;
      case 'tempo_1h':
        tempoAviso = 60 * 60 * 1000;
        break;
      case 'tempo_1h15min':
        tempoAviso = 75 * 60 * 1000;
        break;
      case 'tempo_1h30min':
        tempoAviso = 90 * 60 * 1000;
        break;
      case 'tempo_1h45min':
        tempoAviso = 105 * 60 * 1000;
        break;
      case 'tempo_2h':
        tempoAviso = 120 * 60 * 1000;
        break;
      case 'tempo_6h':
        tempoAviso = 6 * 60 * 60 * 1000;
        break;
      case 'tempo_12h':
        tempoAviso = 12 * 60 * 60 * 1000;
        break;
      case 'tempo_18h':
        tempoAviso = 18 * 60 * 60 * 1000;
        break;
      case 'tempo_24h':
        tempoAviso = 24 * 60 * 60 * 1000;
        break;
      case 'tempo_48h':
        tempoAviso = 48 * 60 * 60 * 1000;
        break;
      default:
        throw new Error('tempo_minimo_aviso inválido');
    }

    // Calcular a hora mínima permitida
    const horaMinima = horaAtual + tempoAviso;

    // Filtrar o array de horários disponíveis para incluir apenas os horários a partir da hora mínima
    const horariosDisponiveis = ArrayAtendimentoTimes.filter((horario) => {
      const horaEmMilissegundos = new Date(
        `${agora.toISOString().slice(0, 10)}T${horario}`
      ).getTime();

      // console.group(horario, horaAtual, new Date());
      // console.log(new Date(`${agora.toISOString().slice(0, 10)}T${horario}`), horaEmMilissegundos);
      // console.log(new Date(horaMinima), horaMinima);
      // console.log(horaEmMilissegundos > horaMinima);
      // console.groupEnd();

      return horaEmMilissegundos >= horaMinima;
    });

    // console.log('horariosDisponiveis', horariosDisponiveis)
    return horariosDisponiveis;
  }

  /**
   * Retorna um array de dias em que uma determinada especialidade selecionada está disponível para atendimento.
   * @param {string} especialidadeSelecionada - Especialidade selecionada.
   * @param {Array} especialidadesDiasDisponiveis - Array de objetos que contêm as propriedades 'especialidade' e 'dias', representando as especialidades e os dias de disponibilidade de cada especialidade.
   * @returns {Array} Array de dias em que a especialidade selecionada está disponível, no formato 'dia'.
   */
  diasQueAtendeArray(
    especialidadeSelecionada: string,
    especialidadesDiasDisponiveis: any
  ): any[] {
    // let keyvalue = especialidadesDiasDisponiveis
    // console.log(Object.keys(keyvalue[0]))

    const dias = especialidadesDiasDisponiveis[especialidadeSelecionada]?.map(
      (dia) => dia?.dias
    );
    const diasQueAtendeArray =
      dias?.flatMap((subArray) => subArray?.map((item) => [item])) || [];
    // console.log('diasQueAtendeArray', diasQueAtendeArray)

    return diasQueAtendeArray;
  }
  /**
   * Retorna um array de horas iniciais que atendem a uma especialidade selecionada a partir de um objeto de dias disponíveis.
   * @param {number} especialidadeSelecionada - O índice da especialidade selecionada.
   * @param {Object} especialidadesDiasDisponiveis - Objeto contendo dias disponíveis para cada especialidade, onde cada chave é o índice da especialidade e cada valor é um array de objetos que contêm a propriedade 'horario_inicial'.
   * @returns {number[]} Array de horas iniciais, representado como números inteiros.
   */
  horarioInicialQueAtendeArray(
    especialidadeSelecionada: number,
    especialidadesDiasDisponiveis: any
  ): number[] {
    const horarioInicial = especialidadesDiasDisponiveis[
      especialidadeSelecionada
    ]
      ?.map((dia) => dia.horario_inicial)
      ?.map((date) => new Date(date)?.getHours());
    const horarioInicialQueAtendeArray = horarioInicial || [];
    // console.log('horarioInicialQueAtendeArray', horarioInicialQueAtendeArray)

    return horarioInicialQueAtendeArray;
  }
  /**
   * Retorna um array com os horários finais disponíveis para uma determinada especialidade, com base em um array de objetos que contêm os dias disponíveis e horários finais.
   * @param {number} especialidadeSelecionada - O índice da especialidade selecionada.
   * @param {Array} especialidadesDiasDisponiveis - Array de objetos que contêm as propriedades 'dia' e 'horario_final', representando os dias disponíveis e horários finais para cada especialidade.
   * @returns {Array} Array de horários finais disponíveis para a especialidade selecionada, no formato de número de horas.
   */
  horarioFinalQueAtendeArray(
    especialidadeSelecionada: number,
    especialidadesDiasDisponiveis: any
  ): number[] {
    const horarioFinal = especialidadesDiasDisponiveis[especialidadeSelecionada]
      ?.map((dia) => dia.horario_final)
      ?.map((date) => new Date(date)?.getHours());
    const horarioFinalQueAtendeArray = horarioFinal || [];
    // console.log('horarioFinalQueAtendeArray', horarioFinalQueAtendeArray)

    return horarioFinalQueAtendeArray;
  }
  /**
   * Retorna um array de objetos que contém informações sobre a duração do tempo de atendimento para uma especialidade selecionada.
   * @param {number} especialidadeSelecionada - Índice da especialidade selecionada.
   * @param {Array} especialidadesDiasDisponiveis - Array de objetos que contêm informações sobre os dias e horários disponíveis para cada especialidade.
   * @returns {Array} Array de objetos que contém informações sobre a duração do tempo de atendimento para a especialidade selecionada.
   */
  duracao_TempoAtendimento(
    especialidadeSelecionada: number,
    especialidadesDiasDisponiveis: any
  ): any[] {
    const duracao =
      especialidadesDiasDisponiveis[especialidadeSelecionada]?.map(
        (dia) => dia.duracao_TempoAtendimento
      ) || [];
    const duracao_TempoAtendimento = [];

    duracao.forEach((durationArray) => {
      durationArray.forEach((durationString) => {
        duracao_TempoAtendimento.push({
          tempo: durationString,
          valor: (
            'Add' +
            durationString
              .replace(' ', '')
              .replace('min', 'm')
              .replace('h', 'h')
          ).replace(/\s+/g, ''),
        });
      });
    });
    // console.log('duracao_TempoAtendimento', duracao_TempoAtendimento)
    return duracao_TempoAtendimento;
  }
  /**
   * Converte um array de strings representando a duração do atendimento por dia da semana em objetos contendo a duração no formato original e uma versão no formato "AddHH:mm" para ser usada na função moment.js.
   * @param {Array} array - Um array de strings representando a duração do atendimento para cada dia da semana, no formato "H horas m minutos".
   * @returns {Array} Um array de objetos, cada um contendo as propriedades 'tempo' e 'valor', representando a duração do atendimento no formato original e no formato "AddHH:mm", respectivamente.
   */
  duracao_TempoAtendimentoByWeekDay(array): any[] {

    console.log('ARRAY DE DURAÇÃO', array);
    const duracao = array;
    const duracao_TempoAtendimento = [];

    duracao?.forEach((durationString) => {
      duracao_TempoAtendimento?.push({
        tempo: durationString,
        valor: (
          'Add' +
          durationString.replace(' ', '').replace('min', 'm').replace('h', 'h')
        ).replace(/\s+/g, ''),
      });
    });

    console.log('duracao_TempoAtendimento', duracao_TempoAtendimento);
    return duracao_TempoAtendimento;
  }

  /**
   * Gera um array de passos de tempo em minutos com base na duração do tempo de atendimento.
   * @param {any} duracao_TempoAtendimento - A duração do tempo de atendimento, em minutos ou no formato 'HH:mm:ss'.
   * @returns {number[]} Array de passos de tempo em minutos.
   */
  minutesStep(duracao_TempoAtendimento: any): number[] {
     console.log('AGENDA SERVICE - DURAÇÃO ATENDIMENTO',duracao_TempoAtendimento)
    const MinutesStepArray = this.ArrayHorasMinInterval(
      duracao_TempoAtendimento
    );
    const minutesStep = MinutesStepArray;
     console.log('MINUTES STEP', minutesStep)

    return minutesStep;
  }

  // ArrayHorasMinInterval(duracao: string): number[] {
  //   let intervalo = 0;
  //   if (duracao.indexOf("h") !== -1) {
  //     intervalo = parseInt(duracao.substring(0, duracao.indexOf("h"))) * 60;
  //     if (duracao.indexOf("m") !== -1) {
  //       intervalo += parseInt(duracao.substring(duracao.indexOf(" ") + 1, duracao.indexOf("m")));
  //     }
  //   } else {
  //     intervalo = parseInt(duracao.substring(0, duracao.indexOf("m")));
  //   }

  //   const minutesStepArray = [15, 20, 30, 40, 60, 80, 120];
  //   const minutesStep = minutesStepArray.filter(value => value <= intervalo);
  //   return minutesStep;
  // }

  /**
   * Converte um array de intervalos de tempo em minutos para um array de intervalos mínimos, calculando o menor intervalo de tempo entre os valores do array e gerando um array de números que representa cada intervalo mínimo.
   * @param {Array} intervals - Array de objetos que contêm a propriedade 'tempo', representando os intervalos de tempo no formato 'XhYmin' ou 'Xh' ou 'Ymin', onde X e Y são valores inteiros.
   * @returns {Array} Array de intervalos mínimos, representando cada valor mínimo no intervalo de tempo mais curto no array de entrada.
   */

  ArrayHorasMinInterval(intervals: string[]) {
    let intervalsInMinutes = intervals?.map((interval) => {
      console.log(interval);
      let time = interval['tempo'];
      let minutes = 0;
      if (time?.includes('h') && time?.includes('min')) {
        minutes = parseInt(time[0]) * 60 + parseInt(time?.slice(2, 5));
      } else if (time?.includes('h') && !time?.includes('min')) {
        minutes = parseInt(time[0]) * 60;
      } else if (!time?.includes('h') && time?.includes('min')) {
        minutes = parseInt(time.slice(0, 2));
      }
      // console.log(minutes)
      return minutes;
    });

    let minimumInterval = Math.min(...intervalsInMinutes);

    let range = [];
    for (let i = 0; i <= 59; i += minimumInterval) {
      range?.push(i);
    }
    console.log('range', range);
    return range;
  }

  /**
   * Converte um array de números em um array de strings de tempo no formato 'HH:00:00'.
   * @param {Array} array - Array de números a serem convertidos.
   * @returns {Array} Array de strings de tempo no formato 'HH:00:00'.
   */
  convertNumberToTimeString(array: number[]): string[] {
    return array.map((num) => {
      const hours = num < 10 ? `0${num}` : `${num}`;
      return `${hours}:00:00`;
    });
  }

  /**
   * Retorna um array de horários disponíveis a partir de um array de horários agendados, com um passo de tempo especificado em minutos.
   * @param {Array} horariosAgendados - Array de horários agendados, no formato 'HH:mm:ss'.
   * @param {number} minutesStep - Passo de tempo em minutos, usado para gerar a janela de horários disponíveis. Padrão: 15.
   * @returns {Array} Array de horários disponíveis, no formato 'HH:mm:ss'.
   */
  getAvailableTimes(horariosAgendados, minutesStep) {
    console.log('MINUTES STESPS SERVICE', minutesStep)
    minutesStep = minutesStep;
    const startHour = new Date(`1970-01-01T${horariosAgendados[0]}`).getTime();
    const endHour = new Date(
      `1970-01-01T${horariosAgendados[horariosAgendados.length - 1]}`
    ).getTime();
    const availableTimes = [];

    // console.group()

    // console.log(startHour)
    // console.log('horariosAgendados',horariosAgendados)
    // console.log(minutesStep)
    // console.groupEnd()

    for (
      let time = startHour;
      time <= endHour;
      time += minutesStep * 60 * 1000
    ) {
      // console.log(time)
      const date = new Date(time);
      const hour = date.getHours();
      const minute = date.getMinutes();
      const second = date.getSeconds();
      availableTimes.push(
        `${hour.toString().padStart(2, '0')}:${minute
          .toString()
          .padStart(2, '0')}:${second.toString().padStart(2, '0')}`
      );
    }
    // console.log('Janela_availableTimes', availableTimes)

    return availableTimes;
  }

  /**
   * Remove horários agendados de um array de horários de atendimento.
   * @param {Array} ArrayAtendimentoTimes - Array de horários de atendimento no formato 'HH:mm:ss'.
   * @param {Array} agendamentos - Array de objetos com as propriedades 'inicial' e 'final', representando os horários de início e fim de cada agendamento, no formato 'HH:mm:ss'.
   * @returns {Array} Array de horários de atendimento livres de agendamentos, no formato 'HH:mm:ss'.
   */
  removerHorariosAgendados(ArrayAtendimentoTimes, agendamentos) {
    const horariosLivres = ArrayAtendimentoTimes.filter((horario) => {
      for (let i = 0; i < agendamentos.length; i++) {
        const inicialDate = new Date(`2022-01-01T${agendamentos[i].inicial}Z`);
        const finalDate = new Date(`2022-01-01T${agendamentos[i].final}Z`);
        const horarioDate = new Date(`2022-01-01T${horario}Z`);
        if (horarioDate >= inicialDate && horarioDate <= finalDate) {
          return false;
        }
      }
      return true;
    });
    console.log(horariosLivres);
    return horariosLivres;
  }

  // removerHorariosAgendados(ArrayAtendimentoTimes: string[], agendamento: { inicial: string, final: string }) {
  //   const horariosLivres = ArrayAtendimentoTimes.filter(horario => {
  //     const horarioDate = new Date(`2022-01-01T${horario}Z`);
  //     const inicialDate = new Date(`2022-01-01T${agendamento.inicial}Z`);
  //     const finalDate = new Date(`2022-01-01T${agendamento.final}Z`);
  //     return (horarioDate < inicialDate || horarioDate > finalDate);
  //   });
  //   console.log(horariosLivres)
  //   return horariosLivres;
  // }

  /**
   * Replica as especialidades de uma lista para um objeto de especialidades, agrupando por nome.
   * @param {Object} info - Um objeto contendo as especialidades a serem replicadas, onde cada chave é uma lista de especialidades separadas por vírgula.
   * @returns {Object} Um objeto de especialidades agrupadas por nome.
   */
  replicarEspecialidades(info: Record<string, Especialidade[]>) {
    const result: Record<string, Especialidade[]> = {};
    for (const especialidadesStr of Object.keys(info)) {
      const especialidades = especialidadesStr.split(',');
      for (const especialidade of especialidades) {
        if (!(especialidade in result)) {
          result[especialidade] = [];
        }
        result[especialidade].push(...info[especialidadesStr]);
      }
    }
    console.log('replicarEspecialidades - result', result);
    return result;
  }

  /**
   * Expande um array de intervalos de tempo em um array de intervalos expandidos com um passo de 15 minutos entre cada intervalo.
   * @param {Array} intervals - Array de objetos que contêm as propriedades 'inicial' e 'final', representando os horários de início e fim de cada intervalo, no formato 'HH:mm:ss'.
   * @returns {Array} Array de horários expandidos, no formato 'HH:mm:ss'.
   */
  gerarHorarios(intervals: Intervalo[]): string[] {
    const expandedIntervals: string[] = [];

    for (const interval of intervals) {
      const initialTime = new Date(`2023-03-04T${interval.inicial}Z`);
      const finalTime = new Date(`2023-03-04T${interval.final}Z`);

      // Adiciona a hora inicial do intervalo atual ao array expandido
      expandedIntervals?.push(interval?.inicial);

      // Adiciona horas intermediárias ao array expandido com um passo de 15 minutos
      let currentTime = new Date(initialTime?.getTime() + 15 * 60000);
      // console.log(
      //   'currentTime',
      //   currentTime,
      //   'finalTime',
      //   finalTime,
      //   currentTime < finalTime
      // );

      while (currentTime < finalTime) {
        // expandedIntervals.push(currentTime.toISOString().substr(11, 8));
        let currentTimePlusINterval = new Date(currentTime?.getTime())
          ?.toISOString()
          ?.substring(11, 19);
        // console.log('currentTimePlusINterval', currentTimePlusINterval)

        expandedIntervals?.push(currentTimePlusINterval);

        currentTime = new Date(currentTime?.getTime() + 15 * 60000);
      }

      // Adiciona a hora final do intervalo atual ao array expandido
      expandedIntervals?.push(interval?.final);
    }
    //  console.log('Array dos horraios há bloquear (stp=15min)', expandedIntervals)
    return expandedIntervals;
  }

  /**
   * Converte um array de dias da semana abreviados em uma matriz de números correspondentes aos dias da semana.
   * @param {Array} diasSemana - Array de strings que contém abreviações dos dias da semana em Português (Dom, Seg, Ter, Qua, Qui, Sex, Sáb).
   * @returns {Array} Array de números que correspondem aos dias da semana (0-6, onde 0 representa Domingo).
   */
  extrairDiasSemana(diasSemana: string[]): number[] {
    const diasSemanaNumeros: { [dia: string]: number } = {
      Dom: 0,
      Seg: 1,
      Ter: 2,
      Qua: 3,
      Qui: 4,
      Sex: 5,
      Sáb: 6,
    };

    return diasSemana.map((dia) => diasSemanaNumeros[dia]);
  }

  extrairDiaSemanaString(diaSemana: number): string {
    // console.log(diaSemana)
    const diaSemanaMap = {
      0: 'Dom',
      1: 'Seg',
      2: 'Ter',
      3: 'Qua',
      4: 'Qui',
      5: 'Sex',
      6: 'Sáb',
    };
    return diaSemanaMap[diaSemana];
  }

  /**
   * Extrai e processa dados de um objeto de profissionais, incluindo dias de trabalho, horários disponíveis e intervalos bloqueados.
   * @param {Object} profissionais - Objeto de profissionais, com cada chave representando um ID de profissional e cada valor representando um objeto contendo informações sobre o profissional.
   * @returns {Object} profissionais -  com um array de intervalo de horas, entre hora inicial e final de cada agendamento/bloqueio, estas serão as horas desabilitadas para a Função DataMaisProxima
   */
  extrairDadosProfissionais(profissionais_object: Profissionais) {
    console.log(profissionais_object);

    let profissionais = { ...profissionais_object };
    const profissionaisProcessados = {};

    for (const id in profissionais) {
      const profissional = profissionais[id];

      const diasSemana = Object.keys(
        profissional.hora_inicial_HoraFinal_PorDia
      );
      const diasSemanaNumeros = this.extrairDiasSemana(diasSemana);

      for (const dataAgendamento in profissional.AgendamentosProfissionalByData) {
        const horarios =
          profissional.AgendamentosProfissionalByData[dataAgendamento].horarios;
        profissional.AgendamentosProfissionalByData[
          dataAgendamento
        ].intervaloTotal = this.gerarHorarios(horarios);
      }

      for (const dataBloqueio in profissional.BloqueiosProfissionalByData) {
        const horarios =
          profissional.BloqueiosProfissionalByData[dataBloqueio].horarios;
        profissional.BloqueiosProfissionalByData[dataBloqueio].intervaloTotal =
          this.gerarHorarios(horarios);
      }

      profissional['diasSemanaNumeros'] = diasSemanaNumeros;
      profissionaisProcessados[id] = profissional;
    }

    return profissionaisProcessados;
  }

  gerarDatasAtendimento(profissionaisProcessados) {
    console.log('profissionaisProcessados>>>>>>>>>', profissionaisProcessados)
    const antecedenciaAgendamento = {
      esta_semana_e_proxima: 14,
      de_3_semanas: 21,
      de_4_semanas: 28,
      de_5_semanas: 35,
      de_6_semanas: 42,
      de_7_semanas: 49,
    };

    var datasAtendimento = {};

    for (const ID in profissionaisProcessados) {
      const PROFISSIONAL = profissionaisProcessados[ID];
      const diasSemanaNumeros = PROFISSIONAL.diasSemanaNumeros;

      const antecedencia =
        antecedenciaAgendamento[PROFISSIONAL.antecedencia_agendamento];
      const hoje = new Date();

      for (let i = 0; i <= antecedencia; i++) {
        const data = new Date(hoje.getTime() + i * (24 * 60 * 60 * 1000));

        const dataString = this.formatarData(data);

        if (!datasAtendimento?.[dataString]) {
          datasAtendimento[dataString] = {
            [ID]: [],
          };
        }

        diasSemanaNumeros.forEach((diaSemana) => {
          const diaSemanaString = this.extrairDiaSemanaString(diaSemana);

          if (diaSemanaString in PROFISSIONAL.hora_inicial_HoraFinal_PorDia) {
            var horarioInicial =
              PROFISSIONAL.hora_inicial_HoraFinal_PorDia[diaSemanaString]
                .horario_inicial;
            var horarioFinal =
              PROFISSIONAL.hora_inicial_HoraFinal_PorDia[diaSemanaString]
                .horario_final;

            horarioInicial =
              horarioInicial < 10 ? '0' + horarioInicial : horarioInicial;
            horarioFinal =
              horarioFinal < 10 ? '0' + horarioFinal : horarioFinal;

            // Inclui os intervalos de tempo apenas se o dia da semana for o especificado
            if (this.extrairDiaSemana(data) === diaSemana) {
              // console.log(data,this.extrairDiaSemana(data),diaSemana)


              // if (!datasAtendimento?.[dataString]?.[ID]) {
              //   datasAtendimento[dataString][ID] = [];
              // }
              datasAtendimento[dataString][ID] = [
                ...this.gerarHorarios([
                  {
                    inicial: `${horarioInicial}:00:00`,
                    final: `${horarioFinal}:00:00`,
                  },
                ])
              ]
              // console.log(datasAtendimento[dataString])
            }
          }
        });
      }
    }
    console.log('datasHorariosAtendimento>>>>>>>>>>>>>>>>>>>>>>>>>>', datasAtendimento);
    return datasAtendimento;
  }

  formatarData(data: Date): string {
    const dia = data.getDate().toString().padStart(2, '0');
    const mes = (data.getMonth() + 1).toString().padStart(2, '0');
    const ano = data.getFullYear().toString();

    // console.log(data, `${ano}-${mes}-${dia}`)

    return `${ano}-${mes}-${dia}`;
  }

  extrairDiaSemana(data: Date): number {
    const diasSemana = ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'];
    const diaSemana = data.getDay();
    return diasSemana.indexOf(diasSemana[diaSemana]);
  }

  /**
   * Remove horários agendados e bloqueados que são menores que o horário atual para o dia atual e profissional específico,
   * levando em consideração o tempo mínimo de aviso para o profissional.
   * @param {Object} datasAtendimento - Um objeto contendo informações sobre datas e horários de atendimento para cada profissional.
   * @param {Object} profissionaisProcessados - Um objeto contendo informações sobre os profissionais processados.
   * @returns {Object} - O objeto datasAtendimento atualizado com horários removidos.
   */

  excluirAgendamentosBloqueios(
    datas_Atendimento: any,
    profissionais_Processados: any
  ) {
    console.log(
      'datasAtendimento1',
      datas_Atendimento,
      'profissionaisProcessados2',
      profissionais_Processados
    );

    let datasAtendimento1 = { ...datas_Atendimento };
    let profissionaisProcessados2 = { ...profissionais_Processados };

    // Obtém a data atual
    const currentDate = new Date();
    const currentWeekDay = this.extrairDiaSemanaString(currentDate.getDay());
    const currentDay = Object.keys(datasAtendimento1);
    const currentTimes: any = {};
    const updatedProcessarTempoMinimoAviso: any = {};

    for (let i = 0; i < currentDay.length; i++) {
      var hoje_dataString = new Date()
        .toLocaleDateString('pt-BR')
        .substring(0, 10)
        .replace(/\//g, '-');
      const parts = hoje_dataString.split('-');
      hoje_dataString = parts[2] + '-' + parts[1] + '-' + parts[0];

      if (currentDay[i] === hoje_dataString) {
        for (const id in profissionaisProcessados2) {

          // Obtém o tempo mínimo de aviso para o profissional
          const minimumNoticeTime = profissionaisProcessados2[id]?.tempo_minimo_aviso;

          // Inicializa o horário atual para o profissional com base no tempo mínimo de aviso
          let currentTime = new Date(currentDate.getTime());
          // Define o horário atual do profissional com base no tempo mínimo de aviso

          switch (minimumNoticeTime) {
            case 'tempo_15min':
              currentTime.setTime(currentTime.getTime() + 15 * 60 * 1000);
              break;
            case 'tempo_30min':
              currentTime.setTime(currentTime.getTime() + 30 * 60 * 1000);
              break;
            case 'tempo_45min':
              currentTime.setTime(currentTime.getTime() + 45 * 60 * 1000);
              break;
            case 'tempo_1h':
              currentTime.setTime(currentTime.getTime() + 60 * 60 * 1000);
              break;
            case 'tempo_1h15min':
              currentTime.setTime(currentTime.getTime() + 75 * 60 * 1000);
              break;
            case 'tempo_1h30min':
              currentTime.setTime(currentTime.getTime() + 90 * 60 * 1000);
              break;
            case 'tempo_1h45min':
              currentTime.setTime(currentTime.getTime() + 105 * 60 * 1000);
              break;
            case 'tempo_2h':
              currentTime.setTime(currentTime.getTime() + 120 * 60 * 1000);
              break;
            case 'tempo_6h':
              currentTime.setTime(currentTime.getTime() + 6 * 60 * 60 * 1000);
              break;
            case 'tempo_12h':
              currentTime.setTime(currentTime.getTime() + 12 * 60 * 60 * 1000);
              break;
            case 'tempo_18h':
              currentTime.setTime(currentTime.getTime() + 18 * 60 * 60 * 1000);
              break;
            case 'tempo_24h':
              currentTime.setTime(currentTime.getTime() + 24 * 60 * 60 * 1000);
              break;
            case 'tempo_48h':
              currentTime.setTime(currentTime.getTime() + 48 * 60 * 60 * 1000);
              break;
            default:
              break;
          }

          let HORARIO_FINAL_CURRENT_DAY = {}
          let date_HORARIO_FINAL_CURRENT_DAY = {}

          HORARIO_FINAL_CURRENT_DAY[id] = profissionaisProcessados2[id]?.hora_inicial_HoraFinal_PorDia?.[currentWeekDay]?.horario_final;
          //gerar um new date com a data atual e o horario de HORARIO_FINAL_CURRENT_DAY
          date_HORARIO_FINAL_CURRENT_DAY[id] = new Date(`${currentDay[i]}T${HORARIO_FINAL_CURRENT_DAY[id]}:00-03:00`);

          console.log('date_HORARIO_FINAL_CURRENT_DAY', id, date_HORARIO_FINAL_CURRENT_DAY[id])

          // Armazena o horário atual do profissional no objeto currentTimes
          currentTimes[id] = currentTime;

          updatedProcessarTempoMinimoAviso[id] = this.processarTempoMinimoAviso2(
            {
              data_hora_inicial: String(
                new Date(currentDate.getTime())
              ).valueOf(),
              data_hora_final: String(
                new Date(currentTimes[id].getTime())
              ).valueOf(),
            }
          );

          console.log(updatedProcessarTempoMinimoAviso);

          // Obter a data e hora atual
          const agora = new Date();

          // Verificar se a dataString corresponde à data atual   new Date(`${dataSelecionada1?.substring(0, 10)}T00:00:00-03:00`)?.toISOString();
          // console.log(new Date(currentDay[1]).toDateString() === agora.toDateString(), new Date(currentDay[1]).toDateString(), agora.toISOString().substring(0, 10))

          // if (new Date(currentDay[1]).toDateString() === agora.toDateString()) {
          // Filtrar os horários que são maiores ou iguais ao horário atual
          let horariosRemoverFiltrados = datasAtendimento1?.[hoje_dataString]?.[
            id
          ]?.filter((horario) => {
            const horarioAtual =
              agora.getHours() +
              ':' +
              agora.getMinutes() +
              ':' +
              agora.getSeconds();
            // console.log(horarioAtual, horario)
            return horario >= horarioAtual;
          });

          // Atualizar a variável horariosRemover para referenciar horariosRemoverFiltrados

          if (datasAtendimento1?.[hoje_dataString]?.[id]?.length) {
            datasAtendimento1[hoje_dataString][id] = datasAtendimento1?.[
              hoje_dataString
            ]?.[id]?.filter((horario) =>
              horariosRemoverFiltrados?.includes(horario)
            );
          }
          // }
        }
      }

      // Loop for-in para percorrer cada profissional em datasAtendimento[currentDay]
      for (const id in datasAtendimento1[currentDay[i]]) {
        for (const datastring in updatedProcessarTempoMinimoAviso[id]) {
          //Exluir os horarios antes do tempo minimo de aviso
          if (datastring in datasAtendimento1) {
            const horariosAgendados =
              updatedProcessarTempoMinimoAviso[id][datastring]?.intervaloTotal;
            const horariosRemover = [...horariosAgendados];
            if (datasAtendimento1?.[datastring]?.[id]?.length) {
              datasAtendimento1[datastring][id] = datasAtendimento1?.[
                datastring
              ]?.[id]?.filter((horario) => !horariosRemover?.includes(horario));
            }
          }
        }

        // Obtém o objeto do profissional

        const profissional = profissionaisProcessados2[id];
        const agendamentosProfissional =
          profissional?.AgendamentosProfissionalByData;
        const bloqueiosProfissional = profissional?.BloqueiosProfissionalByData;

        for (const dataString in agendamentosProfissional) {
          // console.log('dataString', dataString, agendamentosProfissional)

          // Verificar se a data atual está presente em datasAtendimento
          if (dataString in datasAtendimento1) {
            // Obter os horários agendados e bloqueados para a data atual
            const horariosAgendados =
              agendamentosProfissional[dataString]?.intervaloTotal;
            const horariosRemover = [...horariosAgendados];

            // Verificar se há agendamentos para o profissional atual na data atual em datasAtendimento
            if (datasAtendimento1?.[dataString]?.[id]?.length) {
              // console.log(dataString)
              // Remover os horários agendados e bloqueados da data atual para o profissional atual em datasAtendimento
              datasAtendimento1[dataString][id] = datasAtendimento1?.[
                dataString
              ]?.[id]?.filter((horario) => !horariosRemover?.includes(horario));
            }
          }
        }

        for (const dataString in bloqueiosProfissional) {
          // Verificar se a data atual está presente em datasAtendimento
          if (dataString in datasAtendimento1) {
            // Obter os horários agendados e bloqueados para a data atual
            const horariosBloqueados =
              bloqueiosProfissional[dataString]?.intervaloTotal || [];
            var horariosRemover = [...horariosBloqueados];

            // Verificar se há agendamentos para o profissional atual na data atual em datasAtendimento
            if (datasAtendimento1?.[dataString]?.[id]?.length) {
              // Remover os horários agendados e bloqueados da data atual para o profissional atual em datasAtendimento
              datasAtendimento1[dataString][id] = datasAtendimento1[
                dataString
              ]?.[id]?.filter((horario) => !horariosRemover?.includes(horario));
            }
          }
        }
      }

      // Loop for-in para percorrer cada profissional em datasAtendimento[currentDay]
      // for (const id in datasAtendimento1[currentDay[0]]) {
      //   // Obtém o agendamento do profissional
      //   const professionalSchedule = datasAtendimento1[currentDay[0]][id];
      //   // Obtém o horário atual do profissional
      //   const currentTime = currentTimes[id];

      //   // Filtra todos os agendamentos e bloqueios que ocorrem antes do horário atual, levando em consideração o tempo mínimo de aviso do profissional
      //   const filteredSchedule = professionalSchedule.filter((time) => {
      //     const timeHour = time.substring(0, 2);
      //     const timeMinute = time.substring(3, 5);
      //     const timeString = `${timeHour}:${timeMinute}`;

      //     // Cria um objeto de data para o horário agendado
      //     const scheduledTime = new Date(currentDate.getTime());
      //     scheduledTime.setHours(timeHour);
      //     scheduledTime.setMinutes(timeMinute);

      //     // Calculate the minimum notice time for the professional
      //     const minimumNoticeTime = currentTimes[id].getTime() - currentDate.getTime();

      //     // Calculate the minimum time for the scheduled time
      //     const minimumTime = scheduledTime.getTime();

      //     return (minimumTime >= currentTime.getTime());
      //   });

      //   // Atualiza o agendamento do profissional no objeto datasAtendimento
      //   datasAtendimento1[currentDay[i]][id] = filteredSchedule;

      // }
    }

    console.group(hoje_dataString);
    console.log(
      'updatedProcessarTempoMinimoAviso',
      updatedProcessarTempoMinimoAviso
    );
    console.log(datasAtendimento1);
    console.groupEnd();

    // console.log('datasAtendimento1 ',datasAtendimento1)

    return datasAtendimento1;
  }

  //  gerarHorariosComTempoMinimo(currentTime) {
  //   let dataInicial = new Date();
  //   let dataFinal = new Date(dataInicial.getTime() + currentTime.getTime());
  //   let HorariosComTempoMinimo = {};
  //   console.log('dataFinal',dataFinal)
  //   // for (let currentDate = new Date(dataInicial); currentDate <= dataFinal; currentDate.setDate(currentDate.getDate() + 1)) {
  //   //   const dateKey = currentDate.toISOString().slice(0, 10);
  //   //   HorariosComTempoMinimo[dateKey] = {
  //   //     horarios: {
  //   //       inicial: currentDate.getTime() === dataInicial.getTime() ? dataInicial.toISOString().slice(11, 19) : "00:15:00",
  //   //       final: currentDate.toISOString().slice(0, 10) === dataFinal.toISOString().slice(0, 10) ? dataFinal.toISOString().slice(11, 19) : "23:59:59",
  //   //     },
  //   //     intervaloTotal: []
  //   //   };

  //   //   let startTime = new Date(currentDate);
  //   //   startTime.setSeconds(0);
  //   //   startTime.setMilliseconds(0);

  //   //   if (currentDate.getTime() === dataInicial.getTime()) {
  //   //     startTime.setMinutes(dataInicial.getMinutes());
  //   //   } else {
  //   //     startTime.setMinutes(15);
  //   //   }

  //   //   let endTime = new Date(currentDate);
  //   //   endTime.setSeconds(0);
  //   //   endTime.setMilliseconds(0);

  //   //   if (currentDate.toISOString().slice(0, 10) === dataFinal.toISOString().slice(0, 10)) {
  //   //     endTime.setHours(dataFinal.getHours());
  //   //     endTime.setMinutes(dataFinal.getMinutes());
  //   //   } else {
  //   //     endTime.setHours(23);
  //   //     endTime.setMinutes(59);
  //   //   }

  //   //   while (startTime <= endTime) {
  //   //     const timeString = startTime.toISOString().slice(11, 19);
  //   //     HorariosComTempoMinimo[dateKey].intervaloTotal.push(timeString);

  //   //     // Correção: Atualize o objeto Date diretamente
  //   //     startTime = new Date(startTime.getTime() + 15 * 60 * 1000);
  //   //   }
  //   // }
  //   console.log(HorariosComTempoMinimo)
  //   return HorariosComTempoMinimo;
  // }

  obterProximoHorario(datasAtendimento: any): [string, string, string] | null {
    // Obter a data atual
    const currentDate = new Date();

    // Ordenar as datas disponíveis em ordem crescente em relação à data atual
    const datasDisponiveis = Object.keys(datasAtendimento).sort(
      (data1, data2) => {
        const diffData1 = new Date(data1).getTime() - currentDate.getTime();
        const diffData2 = new Date(data2).getTime() - currentDate.getTime();
        return diffData1 - diffData2;
      }
    );

    // Loop for-in para percorrer cada profissional nas datas disponíveis
    for (const dataString of datasDisponiveis) {
      const datasProfissional = datasAtendimento[dataString];

      for (const id in datasProfissional) {
        const horariosProfissional = datasProfissional[id];

        if (horariosProfissional.length > 0) {
          // Retornar o id do profissional e o primeiro horário disponível
          console.log('Horários disponíveis do profissional:', [
            id,
            horariosProfissional,
            dataString,
          ]);
          return [id, horariosProfissional[0], dataString];
        }
      }
    }

    // Caso não haja nenhum horário disponível nos dias seguintes, retornar null
    return null;
  }

  removeEmptyObjects(schedule: Schedule): Schedule {
    const result: Schedule = {};
    for (const date in schedule) {
      const nonEmptyHours = {};
      for (const hour in schedule[date]) {
        if (schedule[date][hour].length > 0) {
          nonEmptyHours[hour] = schedule[date][hour];
        }
      }
      if (Object.keys(nonEmptyHours).length > 0) {
        result[date] = nonEmptyHours;
      }
    }
    // console.log(result)
    return result;
  }

  findAvailableSlot(availabilities: Availabilities): {} {
    console.log(Object.keys(availabilities), Object.values(availabilities));

    // Esse loop percorre os objetos verificando se o array de horarios contem mais de 4 elementos
    // Tentativa de evitar que profssionais que contenham poucos horarios livres sejam selecionados
    for (const data of Object.keys(availabilities)) {
      for (const id in availabilities[data]) {
        // console.log(availabilities[data]?.[id])
        if (availabilities[data]?.[id]?.length < 4) {
          // console.log(availabilities[data])
          delete availabilities[data];
          continue;
        }
      }

      var firstDate = Object.keys(availabilities)[0];
      var firstAvailabilities = availabilities[firstDate];
    }

    let earliestSlotId = '';
    let earliestSlotStartTime = '23:59:59';

    for (const id in firstAvailabilities) {
      var slots = firstAvailabilities[id];

      for (let i = 0; i < slots.length - 2; i++) {
        var startTime = slots[i];
        const thirdSlotTime = slots[i + 2];

        if (
          this.timeDiffInMinutes(startTime, thirdSlotTime) === 30 &&
          this.timeDiffInMinutes(startTime, slots[i + 1]) === 15 &&
          this.timeDiffInMinutes(slots[i + 1], thirdSlotTime) === 15
        ) {
          if (startTime < earliestSlotStartTime) {
            earliestSlotId = id;
            earliestSlotStartTime = startTime;
          }
          break;
        }
      }
    }

    const nextSlot = {
      id: earliestSlotId,
      data: firstDate,
      hora: earliestSlotStartTime,
      HorarioAtendimento: slots,
    };
    console.log(nextSlot);
    return nextSlot;
  }

  timeDiffInMinutes(time1: string, time2: string): number {
    const [h1, m1, s1] = time1.split(':').map((n) => parseInt(n));
    const [h2, m2, s2] = time2.split(':').map((n) => parseInt(n));
    const diffInMinutes = (h2 - h1) * 60 + (m2 - m1);
    return diffInMinutes;
  }

  removeJanelasSemRetorno(obj) {
    obj.attributes.programacao.janela_disponibilidade =
      obj.attributes.programacao.janela_disponibilidade.filter((janela) =>
        janela.tipo_atendimento.includes('Retorno')
      );

    console.log(obj);
    return obj;
  }

  removerAgendamento(horariosAgendados, idAgendamento) {
    const novoHorariosAgendados = { ...horariosAgendados };

    for (let hora in novoHorariosAgendados) {
      for (let data in novoHorariosAgendados[hora]) {
        const horarios = novoHorariosAgendados[hora][data].horarios;

        novoHorariosAgendados[hora][data].horarios = horarios.filter(
          (horario) => horario.id !== idAgendamento
        );
      }
    }

    return novoHorariosAgendados;
  }

  isHoliday(date: Date): boolean {
    const year = date.getFullYear();

    // Fixed holidays
    const fixedHolidays = [
      new Date(year, 0, 1), // New Year's Day - January 1
      new Date(year, 3, 21), // Tiradentes' Day - April 21
      new Date(year, 4, 1), // Labour Day - May 1
      new Date(year, 8, 7), // Independence Day - September 7
      new Date(year, 9, 12), // Our Lady of Aparecida - October 12
      new Date(year, 10, 2), // All Souls' Day - November 2
      new Date(year, 10, 15), // Proclamation of the Republic - November 15
      new Date(year, 11, 25), // Christmas - December 25
    ];

    // Check if the date is one of the fixed holidays
    for (const holiday of fixedHolidays) {
      if (
        date.getDate() === holiday.getDate() &&
        date.getMonth() === holiday.getMonth()
      ) {
        return true;
      }
    }

    // Calculate Easter
    const easter = this.calculateEasterDate(year);

    // Variable holidays based on Easter
    const variableHolidays = [
      easter, // Easter
      addDays(easter, -2), // Good Friday
      addDays(easter, 60), // Corpus Christi
    ];

    // Check if the date is one of the variable holidays
    for (const holiday of variableHolidays) {
      if (
        date.getDate() === holiday.getDate() &&
        date.getMonth() === holiday.getMonth()
      ) {
        return true;
      }
    }

    return false;
  }

  // Calculate Easter date based on the year
  calculateEasterDate(year: number): Date {
    const f = Math.floor;
    const G = year % 19;
    const C = f(year / 100);
    const H = (C - f(C / 4) - f((8 * C + 13) / 25) + 19 * G + 15) % 30;
    const I = H - f(H / 28) * (1 - f(29 / (H + 1)) * f((21 - G) / 11));
    const J = (year + f(year / 4) + I + 2 - C + f(C / 4)) % 7;
    const L = I - J;
    const month = 3 + f((L + 40) / 44);
    const day = L + 28 - 31 * f(month / 4);

    return new Date(year, month - 1, day);
  }




  // ...

  automation(dados: Object) {

    if (!dados || !dados?.['agendamentoId'] || !dados?.['status'] || !dados?.['professionalId']) {
      console.error('Invalid input object. Agendamento ID, status, and professional ID are required.');
      return throwError('Invalid input object. Agendamento ID, status, and professional ID are required.');
    }


    return this.http.post<any>(this.strapiApiUrl + '/automationHandler', dados).pipe(
      catchError((error) => {
        console.error(`Error in automation function: ${error.message}`);

        if (error.status) {
          switch (error.status) {
            case 400:
              console.log('Error 400: Bad Request. The request was malformed or invalid.');
              break;
            case 403:
              console.log('Error 403: Forbidden. You do not have the necessary permissions to access the requested resource.');
              break;
            case 500:
              console.log('Error 500: Internal Server Error. The server encountered an error while processing your request.');
              break;
            default:
              console.log(`Error ${error.status}: An unknown error occurred while processing the request.`);
          }
        } else {
          console.log('An unknown error occurred while processing the request. Please check your network connection and try again.');
        }

        return throwError(error.message);
      }),
    );
  }

  addDays(date, days) {
    const newDate = new Date(date);
    newDate.setDate(date.getDate() + days);
    return newDate;
  }

}
