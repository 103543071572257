import { Component, OnDestroy, OnInit } from '@angular/core';
import { ConnectionService, ConnectionServiceOptions, ConnectionState } from 'ng-connection-service';
import { Subscription, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
// import { fadeInOutAnimation } from '../../src/app/route-animations';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  // animations: [fadeInOutAnimation],

})
export class AppComponent implements OnInit, OnDestroy {
  // public customAnimation = myCustomTransition;

  title = 'demo';

  status!: string;
  currentState!: ConnectionState;
  subscription = new Subscription();

  apiBaseUrl = environment.strapiBaseUrl

  constructor(private connectionService: ConnectionService) {
  }

  ngOnInit(): void {

    const options: ConnectionServiceOptions = {
      enableHeartbeat: false,
      heartbeatUrl: this.apiBaseUrl,
      heartbeatInterval: 300000
    }

    this.subscription.add(
      this.connectionService.monitor(options).pipe(
        tap((newState: ConnectionState) => {
          this.currentState = newState;

          if (this.currentState.hasNetworkConnection && this.currentState.hasInternetAccess) {
            this.status = 'ONLINE';
            
          } else {
            this.status = 'OFFLINE';
            this.connectionErrorAlert();
          }         
          
        })
      ).subscribe({
        next:(result=>{
          //console.log('STATUS', this.status)
        })
      })
    );
  }

  connectionErrorAlert(){

    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Houve um problema em conectar com o servidor, verifique sua conexão com a internet e recarregue a página da aplicação!",
      confirmButtonText:'Recarregar aplicação',
      confirmButtonColor:'#51438e',
      heightAuto:false
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        window.location.reload();
      }
    });

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
