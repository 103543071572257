import { LOCALE_ID, Inject, Injectable, ElementRef } from '@angular/core';
import { CalendarEventTitleFormatter, CalendarEvent, DateFormatterParams, CalendarDateFormatter } from 'angular-calendar';
import { formatDate } from '@angular/common';
import { findIndex } from 'rxjs';

@Injectable()
export class CustomEventTitleFormatter extends CalendarEventTitleFormatter {

  events:any = [];

  constructor(@Inject(LOCALE_ID) private locale: string,
  ) {
    super();

  }



  // you can override any of the methods defined in the parent class
  // weekTooltip(event: CalendarEvent): string {
  //   return;
  // }

  month(event: CalendarEvent): string {   


    return `<b>${formatDate(event.start, 'h:m a', this.locale)}</b> ${event.title
      }`;
  }

  week(event: CalendarEvent, title?, events?: CalendarEvent[]): string {

    let index = this.events.findIndex((item:any)=>item.meta.idAgendamento === event.meta.idAgendamento);

    if(index === -1){
      this.events.push(event);
    }   

    let eventClass = this.getEventCssClass(event, this.events);

    //console.log('EVENT CLASS', this.events)

    let colorClass = ''
    let eventStatus = event?.cssClass;

    switch (eventStatus) {
      case 'completo':
        colorClass = 'completo'
        break;
      case 'confirmado':
        colorClass = 'confirmado'
        break;
      case 'cancelado':
        colorClass = 'cancelado'
        break;
      case 'agendado':
        colorClass = 'agendado'
        break;
      case 'emAtendimento':
        colorClass = 'emAtendimento'
        break;
      case 'noshow':
        colorClass = 'noshow'
        break;
      case 'atrasado':
        colorClass = 'atrasado'
        break;
      default:
        colorClass = 'confirmar'
    }

    if (eventClass?.cssClass === 'extra-small-card') {
      const cardWidth = `${100 / eventClass?.eventsCount}%`;

      return `<div id="eventDetails" class="event-details-class ${colorClass}" >

    </div>`;
    } else {
      return `<div id="eventDetails" class="event-details-class ${colorClass}" >




      <div class="tooltiptext">
          <div class="tooltip-event-pet-name ion-text-capitalize"> ${event?.meta?.tipo_agendamento} para ${event?.meta?.pet}</div>
          <span *ngIf="${event?.meta?.statusAgendamento == 'confirmado'}">
            Confirmado
          </span>
          <span *ngIf="${event?.meta?.statusAgendamento == 'agendado'}">
            Agendado
          </span>
          <span *ngIf="${event?.meta?.statusAgendamento == 'aguardandoAtendimento'}">
            Aguardando Atendimento
          </span>
          <span *ngIf="${event?.meta?.statusAgendamento == 'emAtendimento'}">
            Em atendimento
          </span>
          <span *ngIf="${event?.meta?.statusAgendamento == 'Ncompareceu'}">
            Não Compareceu
          </span>
          <span *ngIf="${event?.meta?.statusAgendamento == 'pacienteAtendido'}">
            Atendido
          </span>
          <span *ngIf="${event?.meta?.statusAgendamento == 'pacientAtrasado'}">
            Atrasado
          </span>
          <span *ngIf="${event?.meta?.statusAgendamento == 'cancelado'}">
            Cancelado
          </span>
          <div class="tooltip-event-pet-name">
            ${event?.meta?.profissional}
          </div>
          ${event.start} | date : "HH:mm"  - ${event.end} | date : "HH:mm"
        </div>




      <div class="event-pet-container">
        <div class="event-pet-icon">
          <img src="../../assets/icon/${event?.meta?.especie === 'Canina' ? 'dog-icon.svg' :
          event?.meta?.especie === 'Felina' ? 'cat-icon.svg' :
            'paw-sharp.svg'
        }">
        </div>
        <div class="event-pet-name">${event?.meta?.pet}</div>
      </div>
      <div class="event-tutor-name ion-text-wrap">
        <div>${event?.meta?.tutor}</div>
      </div>
      <div class="event-pet-container">
        <div class="event-prof-icon">
          <img src="../../assets/icon/profissional-icon.svg">
        </div>
        <div class="event-prof-name">
          ${event?.meta?.profissional}
        </div>
      </div>
    </div>`;

    }


  }

  updateClassBasedOnWidth() {
    const eventDetailsDiv = document?.getElementById('eventDetails');    
    const width = eventDetailsDiv?.clientWidth;
    //console.log(width)
    if (width <= 20) {
      eventDetailsDiv?.classList?.remove('event-details-class');
      eventDetailsDiv?.classList?.add('event-Smalldetails-class');      
    } else {
      eventDetailsDiv?.classList?.remove('event-Smalldetails-class');
      eventDetailsDiv?.classList?.add('event-details-class');      
    }
  }

  isSameDay(e: CalendarEvent<any>, event: CalendarEvent<any>): boolean {
    //console.log('ISSAMEDAY')
    const dateStr1 = e?.start.toISOString().substring(0, 10);
    const dateStr2 = event?.start?.toISOString().substring(0, 10);
    //console.log('START AND', dateStr1, dateStr2)
    ////console.log('isSameDay', dateStr1 === dateStr2, e.meta.idAgendamento)

    return dateStr1 === dateStr2;
  }

  isSameDayAndHourAndMinute(e: CalendarEvent<any>, event: CalendarEvent<any>): boolean {
    const dateStr1 = e?.start.toISOString().substring(0, 16);
    const dateStr2 = event?.start?.toISOString().substring(0, 16);
    // //console.log('isSameDayAndHourAndMinute', dateStr1 === dateStr2, e.meta.idAgendamento)
    return dateStr1 === dateStr2;
  }


  eventsSobrepostos(e1: CalendarEvent, e2: CalendarEvent): boolean {
    return (e1.start < e2.end && e1.start >= e2.start) ||
      (e1.end > e2.start && e1.end <= e2.end) ||
      (e2.start >= e1.start && e2.end <= e1.end);
  }

  getEventsSameTime(event: CalendarEvent, events: CalendarEvent[]): CalendarEvent[] {    

    return events?.filter((e) => {
      
      const sameDay = this.isSameDay(e, event);
      // Verifica se o evento 'e' faz interseção de horários com o evento 'event'
      const eventosSobrepostos = sameDay && this.eventsSobrepostos(e, event);
      
      //console.log('EVENTOS SOBREPOSTOS', events)

      return eventosSobrepostos;
    });
  }







  getEventCssClass(event: CalendarEvent, events: CalendarEvent[]): { cssClass: string, eventsCount: number } {

    ////console.log('EVENTS CSS CLASS', events)

    const eventsAtSameTime = this.getEventsSameTime(event, events);
    const eventsCount = eventsAtSameTime?.length;

    if (eventsCount >= 3) {
      return { cssClass: 'large-card', eventsCount: eventsCount };
    } else {
      return { cssClass: 'large-card', eventsCount: eventsCount };
    }
  }


  // weekTooltip(event: CalendarEvent): string {
  //   const statusMap = {
  //     'confirmado': 'Confirmado',
  //     'agendado': 'Agendado',
  //     'aguardandoAtendimento': 'Aguardando Atendimento',
  //     'Ncompareceu': 'Não Compareceu',
  //     'pacienteAtendido': 'Atendido',
  //     'pacientAtrasado': 'Atrasado',
  //     'cancelado': 'Cancelado'
  //   };

  //   //console.log('TESSSSTE')

  //   const statusText = statusMap[event?.meta?.statusAgendamento] || '';

  //   return `<div class="agenda-mes-event-tooltip">
  //     <div class="tooltip-event-pet-name">${event?.meta?.tipo_agendamento} para ${event?.meta?.pet}</div>
  //     <span>${statusText}</span>
  //     <div class="tooltip-event-pet-name">
  //     ${event?.meta?.profissional}
  //     </div>
  //     ${event?.start} | date : "HH:mm"  -  ${event?.end} | date : "HH:mm"
  //   </div>`;
  // }


  day(event: CalendarEvent, title?, events?: CalendarEvent[]): string {
    return `<b>${formatDate(event?.start, 'HH:mm', this.locale)} - ${formatDate(event?.end, 'HH:mm', this.locale)}</b>` + 
      `<div class="event-pet-container">
          <div class="event-pet-icon">
            <img src="../../assets/icon/${event?.meta?.especie === 'Canina' ? 'dog-icon.svg' :
            event?.meta?.especie === 'Felina' ? 'cat-icon.svg' :
              'paw-sharp.svg'
          }">
          </div>
          <div class="event-pet-name">${event?.meta?.pet}</div>
        </div>
        <div class="event-tutor-name-day ion-text-wrap">
          <div>${event?.meta?.tutor}</div>
        </div>
        <div class="event-pet-container">
          <div class="event-prof-icon">
            <img src="../../assets/icon/profissional-icon.svg">
          </div>
          <div class="event-prof-name">
            ${event?.meta?.profissional}
          </div>
        </div>
      </div>`
      ;
  }

}

@Injectable()
export class CustomDateFormatter extends CalendarDateFormatter {
  // you can override any of the methods defined in the parent class

  public dayViewHour({ date, locale }: DateFormatterParams): string {
    return formatDate(date, 'HH:mm', locale);
  }

  public weekViewHour({ date, locale }: DateFormatterParams): string {
    return this.dayViewHour({ date, locale });
  }


}
