import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private variableSource = new BehaviorSubject<string>('');
  currentVariable = this.variableSource.asObservable();

  changeVariable(value: string) {
    this.variableSource.next(value);
  }
}
